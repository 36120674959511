import { useCallback, useState, forwardRef, useMemo } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Stack } from '@mui/system';
import { ToggleButton } from '@mui/material';
import { usePathname, useRouter, useSearchParams } from 'src/routes/hooks';
import { useTheme } from '@mui/material/styles';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

interface IFiltersDialogProps {
  open: boolean;
  onClose: () => void;
  addQuery: (data: any) => void;
}

export default function FiltersDialog({ open, onClose, addQuery }: IFiltersDialogProps) {
  const searchParams = useSearchParams();
  const theme = useTheme();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const router = useRouter();
  const pathname = usePathname();

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);

      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const deleteQueryString = useCallback(
    (name: string) => {
      const params = new URLSearchParams(searchParams);

      params.delete(name);
      return params.toString();
    },
    [searchParams]
  );
  const onSelectTag = (key: string, value: string) => {
    const queryString =
      searchParams.get(key) !== value ? createQueryString(key, value) : deleteQueryString(key);

    router.push(`${pathname}?${queryString}`);
  };

  const currentPrice = useMemo(() => searchParams.get('price'), [searchParams]);
  const currentDate = useMemo(() => searchParams.get('date'), [searchParams]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        mt: '50%',
        backgroundColor: 'transparent',
        '& .MuiDialog-paperFullScreen': {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          borderBottom: `solid 1px #919EAB`,
        }}
      >
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Filters
          </Typography>
          <Button autoFocus color="inherit" onClick={onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Stack sx={{ height: 1, pt: 3, px: 2, alignItems: 'flex-start' }} gap={4}>
        <Stack direction="column" gap={1}>
          <Typography color={theme.palette.grey[500]}>Price</Typography>
          <Stack direction="row" gap={1}>
            <ToggleButton
              sx={{
                // minWidth: '50%',
                my: 1,
                px: 4,
                fontSize: '0.8rem',
              }}
              key="free"
              value="free"
              selected={currentPrice === 'free'}
              onChange={() => onSelectTag('price', 'free')}
            >
              <Typography>Free</Typography>
            </ToggleButton>
            <ToggleButton
              sx={{
                // minWidth: '50%',
                my: 1,
                px: 4,
                fontSize: '0.8rem',
              }}
              key="paid"
              value="paid"
              selected={currentPrice === 'paid'}
              onChange={() => onSelectTag('price', 'paid')}
            >
              <Typography>Paid</Typography>
            </ToggleButton>
          </Stack>
        </Stack>
        <Stack direction="column" gap={1}>
          <Typography color={theme.palette.grey[500]}>Date</Typography>
          <Stack direction="row" gap={1}>
            <ToggleButton
              sx={{
                // minWidth: '50%',
                my: 1,
                px: 3,
                fontSize: '0.8rem',
              }}
              key="Today"
              value="Today"
              selected={currentDate === 'Today'}
              onChange={() => onSelectTag('date', 'Today')}
            >
              <Typography>Today</Typography>
            </ToggleButton>
            <ToggleButton
              sx={{
                // minWidth: '50%',
                my: 1,
                px: 3,
                fontSize: '0.8rem',
              }}
              key="Tomorrow"
              value="Tomorrow"
              selected={currentDate === 'Tomorrow'}
              onChange={() => onSelectTag('date', 'Tomorrow')}
            >
              <Typography>Tomorrow</Typography>
            </ToggleButton>
            <ToggleButton
              sx={{
                // minWidth: '50%',
                my: 1,
                px: 3,
                fontSize: '0.8rem',
              }}
              key="ThisWeek"
              value="ThisWeek"
              selected={currentDate === 'ThisWeek'}
              onChange={() => onSelectTag('date', 'ThisWeek')}
            >
              <Typography>This week</Typography>
            </ToggleButton>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
