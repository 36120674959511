// @mui
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import { useSearchParams, usePathname, useRouter, ReadonlyURLSearchParams } from 'next/navigation';
import { useCallback, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import Iconify from 'src/components/iconify';
import TopDialog from 'src/components/dialog/top-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import EventSearch from 'src/components/event-search/event-search';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetCities } from 'src/api/city';
import { useQueryState } from 'nuqs';
import { EventTag } from '../../types';
import { IEventTag } from '../../models/event-tag.model';
import LocationAndSearch from './location-search';
import FiltersDialog from './filters-dialog';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  filters: IEventTag[];
}

export default function FiltersBar({ filters }: Props) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams()! as ReadonlyURLSearchParams & {
    size: number;
  };
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const { data: CitiesList } = useGetCities();

  const [lat, setLat] = useQueryState('lat');
  const [lng, setLng] = useQueryState('lng');
  const [place, setPlace] = useQueryState('place');

  const showEventSearch = useBoolean();
  const showEventsFilters = useBoolean();
  const mdDown = useResponsive('down', 'md');

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);

      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const deleteQueryString = useCallback(
    (name: string) => {
      const params = new URLSearchParams(searchParams);

      params.delete(name);
      return params.toString();
    },
    [searchParams]
  );
  const onSelectTag = (tag: EventTag) => {
    let newActiveFilters = [...activeFilters];

    if (newActiveFilters.includes(tag)) {
      newActiveFilters = newActiveFilters.filter((t) => t !== tag);
    } else {
      newActiveFilters.push(tag);
    }

    setActiveFilters(newActiveFilters);

    const queryString =
      newActiveFilters.length > 0
        ? createQueryString('tags', newActiveFilters.join(','))
        : deleteQueryString('tags');

    router.push(`${pathname}?${queryString}`);
  };

  const addQuery = useCallback(
    (location: { lat: number; lng: number; place?: string }) => {
      setLat(location.lat.toString());
      setLng(location.lng.toString());
      setPlace(place);
    },
    [place, setLat, setLng, setPlace]
  );

  return (
    <Stack spacing={1} px={{ xs: 1, md: 0 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={{ xs: 1, md: 0 }}
      >
        <LocationAndSearch data={CitiesList} addQuery={addQuery} />
        {mdDown && (
          <Stack direction="row" gap={1}>
            <IconButton
              sx={{
                backgroundColor: '#000',
                // px: 2,
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventSearch.onTrue}
            >
              <Iconify width={20} icon="icon-park-outline:search" />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: '#000',
                // px: 2,
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventsFilters.onTrue}
            >
              <Iconify width={20} icon="mi:filter" />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        sx={{
          // position: 'sticky',
          // top: 64,
          pb: 0.5,
          // zIndex: 1200,
          // transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;`,
          // backdropFilter: 'blur(8px)',
          // backgroundColor: 'rgba(25, 25, 25, 0.8)',
        }}
      >
        <Scrollbar>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              overflow: 'auto',
              scrollbarWidth: 'none', // Hide the scrollbar for firefox
              msOverflowStyle: {
                display: 'none',
              },
              '&::-webkit-scrollbar': {
                display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
              },
              '&-ms-overflow-style:': {
                display: 'none', // Hide the scrollbar for IE
              },
              '& ::-webkit-scrollbar-track': {
                background: 'transparent' /* You can set a background color here if needed */,
              },
            }}
          >
            {filters.map((filter) => (
              <Stack
                direction="row"
                key={filter.id}
                spacing={1}
                alignItems="center"
                onClick={() => onSelectTag(filter.id)}
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 1,
                  minWidth: 'fit-content',
                  bgcolor: activeFilters.includes(filter.id) ? 'white' : '#000',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 650,
                    color: (theme) =>
                      activeFilters.includes(filter.id) ? '#000' : theme.palette.common.white,
                  }}
                >
                  {filter.name}
                </Typography>
                <Iconify
                  width={20}
                  icon={filter.icon}
                  sx={{
                    color: (theme) =>
                      activeFilters.includes(filter.id) ? '#000' : theme.palette.common.white,
                    transition: (theme) =>
                      theme.transitions.create('all', {
                        duration: theme.transitions.duration.shortest,
                      }),
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </Scrollbar>
        {!mdDown && (
          <Stack direction="row" gap={1}>
            <IconButton
              sx={{
                backgroundColor: '#000',
                // px: 2,
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventSearch.onTrue}
            >
              <Iconify width={20} icon="icon-park-outline:search" />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: '#000',
                // px: 2,
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventsFilters.onTrue}
            >
              <Iconify width={20} icon="mi:filter" />
            </IconButton>
          </Stack>
        )}
        <TopDialog dialog={showEventSearch}>
          <EventSearch dialog={showEventSearch} />
        </TopDialog>
      </Stack>
      <FiltersDialog
        open={showEventsFilters.value}
        onClose={showEventsFilters.onFalse}
        addQuery={addQuery}
      />
    </Stack>
  );
}
