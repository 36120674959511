// @mui
import Stack from '@mui/material/Stack';
import { useCallback, useState } from 'react';
import CitySelector from '../../components/autocomplete/city-selector';
import { AggregatedCityModel, City } from '../../types';

// ----------------------------------------------------------------------

interface Props {
  addQuery?: any;
  data?: AggregatedCityModel[];
}

export default function LocationAndSearch({ addQuery, data }: Props) {
  const [city, setCity] = useState<City>();

  const onSelectCity = useCallback(
    (name: City) => {
      setCity(name);
      // if (city?.latitude && city?.longitude) {
      addQuery({
        lat: name?.latitude,
        lng: name?.longitude,
        place: name?.name,
      });
    },
    [addQuery]
  );

  return (
    <Stack direction="row" alignItems="center">
      <CitySelector data={data} selectedCity={city} onSelectCity={(c) => onSelectCity(c)} />
    </Stack>
  );
}
