/* eslint-disable no-nested-ternary */
import { useTheme } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { ListItemAvatar, TextField, Typography } from '@mui/material';
import { capitalize, debounce, uniqBy } from 'lodash';
import { SearchResponse } from '@algolia/client-search';
import {
  useAlgolia,
  getEventSearchFilters,
  AlgoliaRecord,
  EventRecord,
  OrganizationRecord,
} from './useAlgolia';
import { useRouter } from '../../routes/hooks';
import { ReturnType } from '../../hooks/use-boolean';
import Image from '../image/image';
import { formatDate } from '../../utils/format-time';
import { Address, City } from '../../types';

interface Props {
  dialog: ReturnType;
}

export default function EventSearch({ dialog }: Props) {
  const theme = useTheme();
  const router = useRouter();
  const [searchResults, setSearchResults] = useState<AlgoliaRecord[]>([]);
  const { client } = useAlgolia();

  const ref = useOnclickOutside(() => {
    setSearchResults([]);
  });

  const createUniqueKey = (record: any) => {
    const name = record.name || '';
    const title = record.title || '';
    return `${name}-${title}`;
  };

  const handleSearch = useCallback(
    async (value: string) => {
      const { results } = await client.multipleQueries<AlgoliaRecord>([
        {
          indexName: 'production',
          query: value,
          params: { filters: getEventSearchFilters(), hitsPerPage: 3 },
        },
        {
          indexName: 'cities',
          query: value,
          params: { hitsPerPage: 3 },
        },
        {
          indexName: 'organizations',
          query: value,
          params: { hitsPerPage: 3 },
        },
      ]);
      const cityResults = (results[1] as SearchResponse<City>).hits;
      const eventResults = (results[0] as SearchResponse<EventRecord>).hits;
      const organizationResults = (results[2] as SearchResponse<OrganizationRecord>).hits;

      const combinedResults = [...cityResults, ...eventResults, ...organizationResults];
      const uniqueResults = uniqBy(combinedResults, createUniqueKey);

      setSearchResults(uniqueResults);
    },
    [client]
  );

  const debouncedSearch = debounce((value: string) => {
    handleSearch(value);
  }, 500);

  const handleChangeSearch = (query: string) => {
    debouncedSearch(query);
  };

  const handleSelect = (result: AlgoliaRecord) => {
    dialog.onFalse();
    if ('_geoloc' in result && 'name' in result) {
      // city
      router.push(`/?place=${result.name}&lat=${result._geoloc.lat}&lng=${result._geoloc.lng}`);
    } else if ('title' in result) {
      // event
      router.push(`event/${result.id}`);
    } else {
      // organizer
      router.push(`/${result.normalizedName}`);
    }

    setSearchResults([]);
  };

  const formatAddress = (address: Address) => {
    if (address?.city) {
      return `${address.city}, ${address.countryCode}`;
    }

    if (address?.region) {
      return `${address.region}, ${address.countryCode}`;
    }

    return `${address.country}`;
  };

  const getCoverURL = (result: AlgoliaRecord) => {
    if ('cover' in result) {
      return result.cover?.url;
    }
    if ('logo' in result) {
      return result.logo?.url;
    }

    if ('picture' in result) {
      return result.picture.url;
    }

    return '';
  };

  const getTitle = (result: AlgoliaRecord) => {
    if ('title' in result) {
      return result.title;
    }
    if ('name' in result) {
      return capitalize(result.name);
    }
    return '';
  };

  const getSearchDetails = (result: AlgoliaRecord) => (
    <>
      {'date' in result && (
        <Typography>
          {formatDate(new Date(result.startDate ?? result.date), result.timezone)}
        </Typography>
      )}

      {'address' in result && <Typography>{formatAddress(result.address)}</Typography>}
      {'name' in result && 'countryCode' in result && (
        <Typography>{`${result.name}, ${result.countryCode}`}</Typography>
      )}
    </>
  );

  return (
    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
    <div ref={ref} role="combobox" aria-expanded={searchResults.length > 0}>
      <TextField
        variant="outlined"
        placeholder="Search for an event, organizer or a city"
        type="search"
        onChange={(e) => handleChangeSearch(e.target.value)}
        fullWidth
        sx={{
          backgroundColor: '#000',
        }}
      />

      {searchResults.length > 0 && (
        <List
          sx={{
            px: 1,
            maxWidth: '100%',
            backgroundColor: 'black',
            borderRadius: 2,
            pb: 2,
            boxShadow: theme.customShadows.z20,
          }}
        >
          {searchResults.map((result, idx) => (
            <ListItem
              key={'id' in result ? result.id : idx}
              sx={{ cursor: 'pointer', textDecoration: 'none', px: 0 }}
              onClick={() => handleSelect(result)}
            >
              <ListItemAvatar>
                <Image src={getCoverURL(result)} width={78} height={78} />
              </ListItemAvatar>
              <ListItemText primary={getTitle(result)} secondary={getSearchDetails(result)} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}
